/* @import url("https://fonts.googleapis.com/css?family=Libre+Franklin:400,700&subset=latin"); */
/* 
html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 1);
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  overflow: hidden;
} */
.child {
  transform: translate(-50%, -50%);
} 

